.fl-module-info-banner {

  .fl-module-content {

      .uabb-ultb3-info {
        position: absolute;
        padding: 0;
        width: 100%;
        height: 100%;

        .uabb-ultb3-title {
          padding: 1em;
          background: rgba($color-secondary, 0.75);
          font-size: $font-size-base;
          font-weight: 600;
          text-transform: uppercase;
        }

        .uabb-button-wrap {
          position: absolute;
          width: 100%;
          bottom: 1.5em;
        }
      }
    }
}
