#header {
    @extend .hidden-xs;
    @extend .hidden-is;
    background: url(../images/bg-pattern.png) repeat;

    // First line of the website. Often contains contact information
    .header-contact {
        font-size: $font-size-small;

        .opening-times {
            @include make-sm-column(5);
        }

        .contact-infos {
            @include make-sm-column(7);

            ul {
                @extend .nav;
                @extend .nav-right;

                li, a {
                    font-size: $font-size-small;
                }
            }
        }
    }

    // Wrapper for site logo and header widgets
    .header-content {
        .site-logo {
            @include make-sm-column(4);
            text-align: center;

            .logo-link {
                display: block;
                height: 100px;
                background-color: $brand-primary;
            }

            .custom-logo-link {
              display: block;
              margin: 0 auto;
              padding-bottom: 1em;
              max-width: 175px;
            }
        }

        .header-widgets-left,
        .header-widgets-right {
            @include make-sm-column(4);
            padding-top: 2em;
        }
    }
}
