/* marcellus-regular - latin */
@font-face {
  font-family: 'Marcellus';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/marcellus-v5-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Marcellus'), local('Marcellus-Regular'),
       url('../fonts/marcellus-v5-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/marcellus-v5-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/marcellus-v5-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/marcellus-v5-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/marcellus-v5-latin-regular.svg#Marcellus') format('svg'); /* Legacy iOS */
}
