/*******************************************************
**** Overflow settings for fixed navbar / offcanvas ****
*******************************************************/
@media screen and (max-width: $screen-is-max) {
	body,
	html,
	.off-canvas-wrap,
	.off-canvas-wrap .inner-wrap,
	.content-wrap {
		height: 100%;
	}

	.content-wrap {
		overflow-y: auto;
		overflow-x: hidden;
		-webkit-overflow-scrolling: touch;

		.move-left & {
			overflow-x: auto;
		}
	}
}



/***********************************************************
*************** TOPBAR STYLING (OFFCANVAS)******************
* See /common/variables.scss for animation settings
***********************************************************/
.topbar {
	transition-property: transform;
	transition-duration: $offcanvas-duration;
	transition-delay: $offcanvas-delay;
	transition-timing-function: $offcanvas-timing;

	.move-left & {
		transform: translate3d(- $offcanvas-width, 0, 0);
	}
}



/***********************************************************
****************** OFF-CANVAS-STYLES ***********************
* See /common/variables.scss for color & animation settings
***********************************************************/
.off-canvas-wrap {
	position: relative;
	width: 100%;
	overflow: hidden;
	-webkit-backface-visibility: hidden;

	&.move-right, &.move-left {
		min-height: 100%;
		-webkit-overflow-scrolling: touch;

		.exit-off-canvas {
			display: block;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			z-index: 1002;
			background: rgba(0, 0, 0, 0.2);
			transition: background 300ms ease;
			cursor: pointer;
			-webkit-backface-visibility: hidden;
			-webkit-tap-highlight-color: transparent;
		}
	}

	&.move-right {
		// Shift Content to the right
		> .inner-wrap {
			transform: translate3d($offcanvas-width, 0, 0);
		}
	}

	&.move-left {
		// Shift Content to the left
		> .inner-wrap {
			transform: translate3d(- $offcanvas-width, 0, 0);
		}
	}

	// Content wrapper
	.inner-wrap {
		position: relative;
		z-index: 1001;
		width: 100%;
		transition-property: transform;
		transition-duration: $offcanvas-duration;
		transition-delay: $offcanvas-delay;
		transition-timing-function: $offcanvas-timing;

		&::before, &::after {
			display: table;
			content: " ";
		}

		&::after {
			clear: both;
		}
	}

	// Right offcanvas menu styles
	.right-off-canvas-menu {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		z-index: -1;
		box-sizing: content-box;
		width: $offcanvas-width;
		background: $offcanvas-bg;
		color: $offcanvas-color;
		overflow-x: hidden;
		overflow-y: auto;
		transition-property: transform;
		transition-duration: $offcanvas-duration;
		transition-delay: $offcanvas-delay;
		transition-timing-function: $offcanvas-timing;
		transform: translate3d(100%, 0, 0);
		-webkit-backface-visibility: hidden;
		-webkit-overflow-scrolling: touch;

		@media screen and (min-width: $screen-sm-min) {
			display: none;
		}

		.fl-builder-edit & {
			display: none;
		}

		// First Level ul
		.menu {
			@extend .nav;
			// General Menu Item Styles
			min-height: 1000px;

			.menu-item {
				// Toggles the sub-menus based on class
				&.open {
					> .sub-menu {
						display: block;
					}

					> .toggle {
						.fa {
							display: block;
							line-height: inherit;
							transform: rotate(90deg);
							-ms-transform: rotate(90deg);
						}
					}
				}
			}

			> .menu-item {
				border-top: 1px solid $gray-lighter;

				a {
					display: block;
					color: $navbar-default-link-color;
					padding: 1em;
				}

				// Toggle Indicators
				.toggle {
					display: inline-block;
					position: absolute;
					top: 0;
					right: 0;
					z-index: 10;
					padding: 1em;
					color: $navbar-default-link-color;
					text-align: center;

					.fa {
						transition: all 200ms ease-in-out;
						transform: rotate(0deg);
						-ms-transform: rotate(0deg);
					}

					&::before {
						display: block;
						position: absolute;
						top: 1px;
						left: 0;
						bottom: 1px;
						width: 1px;
						background: rgba(255,255,255,0.25);
						content: ' ';
					}
				}

				// Close sub-menus by default
				.sub-menu {
					display: none;
					padding-top: 0.25em;
					padding-bottom: 0.25em;
					background: $gray-lighter;

					> .menu-item {

						.toggle {
							padding: 0.6em 1.2em;
						}

						a {
							padding: 0.5em 0;
							padding-left: 1.75em;
						}

						.sub-menu {
							padding-left: 0;

							.menu-item {
								a {
									font-size: 0.8em;
									padding-left: 2.5em;
								}

								&.current-menu-item {
									> a {
										background: transparent;
									}
								}
							}
						}
					}
				}

				// Current menu-ancestor styles
				.current-menu-ancestor,
				.current-menu-item {
					> a {
						color: $desktop-nav-link-active-color;
						background: $desktop-nav-link-active-bg;
					}
					.toggle {
						color: $desktop-nav-link-active-color;
					}
				}

				// Current menu-item styles
				.current-menu-item {
					background: $color-tertiary;

					> a {
						color: $desktop-nav-link-active-color;
						font-weight: bold;
					}
				}
			}
		}
	}
}
