/****************************************************
*************** General Footer Styles ***************
****************************************************/
#testimonials {
    margin: 0 auto;
    max-width: 720px;
}
#footer {
  background: $color-primary;
}




/****************************************************
******************** Footer Menu ********************
****************************************************/
.footer-menu {
    @extend .hidden-xs;
    @extend .hidden-is;
    margin-bottom: 1em;
    background: darken($color-primary, 10%);

    nav {
        @extend .container;

        #footer-nav {
            // Generic #footer-nav list-item styles
            text-transform: uppercase;

            li {
              color: #fff;

              &.pipe-separator {
              }

              a {
                padding: 1em;
                color: inherit;
              }

                &.current-menu-item > a {
                    font-weight: bold;
                }
            }

            > li {
              display: flex;
              flex: 0 1 auto;
              flex-grow: 2;
              vertical-align: top;

                // First Level Sub-Menus
                > .sub-menu {
                    .menu-item a {
                        font-size: $font-size-small;
                    }
                }
            }
        }
    }
}




/****************************************************
************ Footer Widget Area Styles **************
****************************************************/
.footer-social-links {
    @extend .container;
    text-align: center;

    .social-links-list {
        @extend .nav;
        @extend .nav-center;
    }
}




/****************************************************
************ Footer Widget Area Styles **************
****************************************************/
.footer-widgets {
    @extend .container;
    padding-top: 2em;
    padding-bottom: 2em;
    color: #fff;
    text-align: center;

    a {
      color: inherit;
      text-decoration: underline;
    }
    .textwidget {
      max-width: 500px;
      margin: 0 auto;
    }
}




/****************************************************
******** Copyright Line (Last Line of site) *********
****************************************************/
.copyright {
  padding-top: 1em;
  padding-bottom: 2.5em;
  background: #fff;

  .cookies-not-set & {
    padding-bottom: 6.5em;
  }

  @media screen and (min-width: $screen-sm-min) {
    .cookies-not-set & {
      padding-bottom: 4em;
    }
  }
}

.copyright-row {
    @extend .row;
    font-size: $font-size-small;

    // Unternehmensname, Copyright, Adresse etc.
    .copyright-info {
        @extend .nav;
        @extend .nav-left;
        @extend .hidden-xs;
        @extend .hidden-is;
        @include make-sm-column(7);
        text-align: center;
        clear: both;

        @media screen and (min-width: $screen-sm-min) {
            text-align: left;
        }

        li {
            @extend .pipe-after;

            &::after {
                display: none;
            }
            @media screen and (min-width: $screen-sm-min){
                float: left;
                margin-right: 0;

                &::after {
                    display: inline-block;;
                }
            }
        }
    }

    // Copyright Navigation (Kontakt, Impressum, Datenschutz etc.)
    .copyright-navigation {
        @include make-sm-column(5);
        @media screen and (max-width: $screen-is-max){
        clear: both;
        }

        .menu {
            @include list-unstyled;

            @media screen and (min-width: $screen-sm-min){
                float: right;
            }
            .menu-item {
                float: none;
                display: block;
                text-align: center;
                @extend .pipe-after;

                &::after {
                    display: none;
                }

                @media screen and (min-width: $screen-sm-min){
                    float: left;

                    &::after {
                        display: inline-block;;
                    }
                }
            }
        }
    }
}
