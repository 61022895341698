/****************************************************
******* Breadcrumb (mostly on 2-column pages) *******
****************************************************/
.breadcrumbs {
    padding-top: .33em;
    font-size: $font-size-small;

    span {
        display: inline-block;
        margin: 0 2px;
        padding-bottom: .25em;
    }
}
