// Search form
.search-form {
    @extend .form-inline;
}
.search-form label {
    @extend .form-group;
    font-weight: normal;
}
.search-form .search-field {
    @extend .form-control;
}
.search-form .search-submit {
    @extend .btn;
    @extend .btn-default;
}

// Caldera Forms
.caldera-grid {
  .caldera-forms-gdpr-field-label {
     margin-left: 0 !important;
     padding-left: 0;

     &:empty {
       margin-top: -1.5em;
     }
   }
}
