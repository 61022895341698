.fl-module-blog-posts {

  .fl-module-content {

    .uabb-post-grid-1 {
      .uabb-post-thumbnail {
        padding-bottom: $grid-gutter-width/2;
      }
      .uabb-blog-posts-description {
        padding-top: 0;
      }
      @media screen and (min-width: $screen-md-min) {
        .uabb-post-thumbnail {
          width: 35%;
        }
        .uabb-blog-post-content {
          width: 65%;
        }
      }
    }

    .uabb-blog-posts-carousel {

          .uabb-post-thumbnail {
            img {
              display: block;
            }
            a {
              display: block;
              border: 1em solid #fff;
              box-shadow: 0 0 6px 2px rgba(0,0,0,0.1);
            }
          }

          @media screen and (min-width: $screen-md-min) {
            .uabb-post-thumbnail {
              width: 35%;
            }
            .uabb-blog-post-content {
              width: 65%;
            }
          }
          }

          .slick-arrow {
            i, i:hover {
              font-size: 60px;
            }
          }
    }
}
