/****************************************************
************** General Stylesheet Rules *************
*****************************************************
* 4 Spaces Tab Indent
* large scope style blocks (e.g: organs, pages) are marked with 3 line star comments
* Smaller sub style blocks (e.g: molecules) are marked with a single line comment
* One line space between selectors inside nested selectors
* One line space between root level selectors
* Four lines space between logical style blocks
* Order style rules corresponding to the DOM order of the elements they're applied to
*
* Order for style properties:
* 1) display
* 2) position
* 3) box-model
* 4) color/font
* 5) others
*
* If not otherwise specified the properties are ordered in the following manner:
* from outside to inside of the element (margin, border, padding, width, height)
* from top to left (top, right, bottom, left)
* alphabetical
*/




/****************************************************
***************** Dev Support Styles ****************
****************************************************/
#header,
#hero,
#desktop-nav > div,
.fl-row-content,
.container,
main,
aside {
  //border: 1px solid $gray;
}




/****************************************************
************** Generic Element Styles ***************
****************************************************/
img {
  max-width: 100%;
  height: auto;

  // Prevent oversize bb-plugin lightbox portrait images
  &.mfp-img {
      @media screen and (max-width: $screen-is-max) {
          margin-top: 2em;
          max-height: 65vh !important;
      }
  }
}

::selection {
  background: $color-primary;
  color:#FFF;
}

::-moz-selection {
  background: $color-primary;
  color:#FFF;
}

// Anchor element for scroll-top btn
#top {
  visibility: hidden;
  position: absolute;
  top: 0;
}




/****************************************************
*************** Global Content Styles ***************
****************************************************/
main {
  a {
    color:$color-primary;
    font-weight: 700;
    text-decoration: underline;

    &:hover{
      color:lighten($color-primary, 10%)
    }
  }

  address {
        margin: 0;
  }

  blockquote {}

  figure {

    figcaption {}
  }

  hr {}

  p {}

  .horizontal-list {
    list-style: none;

    li {
        margin-bottom: 2%;
        padding: .5em;
        background: $gray-lighter;

        @media screen and (min-width: 600px) {
            float: left;
            margin-left: 1%;
            margin-right: 1%;
            padding: .75em;
            width: 46%;
        }

        .no-sidebar-primary & {
            @media screen and (min-width: $screen-md) {
                float: left;
                width: 31.33%;
                margin-left: 1%;
                margin-right: 1%;
            }
        }
    }
}

  .read-more {
    white-space: nowrap;

    .fa {
      display: inline-block;
      margin-left: .5em;
      font-size: .75em;
    }
  }
}
.sticky {}

a[class^="sc-"] , span[class^="sc-"] {
    display: inline-block;
    color: inherit;
}
span[class^="sc-label"], .sc-zip {
    margin-right: .25em;
}
.sc-email {
    margin-bottom: .5em;
}
ol, ul {
    margin: 0;
    padding-left: 1em;
}
