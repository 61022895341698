/****************************************************
********** General Sidebar & Widget Styles **********
****************************************************/
.sidebar {
    @extend .hidden-xs;
    @extend .hidden-is;
    padding-bottom: 1em;

    .widget {
        background: $color-tertiary;
        color: $color-primary;
        font-size: $font-size-small;
        text-align: center;

        a {
          color: inherit;
        }

        &.widget_media_image {
          background: $color-secondary;
          padding: 1.25em 0 .75em;
          margin-top: 4em;

          img {
            margin-top: -4em;
            width: 85px;
            height: 85px !important;
            background: #fff;
            border: 10px solid #fff;
            border-radius: 100%;
            overflow: hidden;
          }
        }

        .textwidget {
          padding: 1em;
        }
    }
}



/****************************************************
************** Sidebar Sub-Menu Styles **************
****************************************************/
.submenu-widget {

    .menu {
        @extend .nav;

        li {
            border-bottom: 1px solid $color-tertiary;

            &:last-child {
                border-bottom: none;
            }

            a {
                display: block;
                padding: .75em;
            }

            // Shrink padding for parents, for better relation to children
            &.menu-item-has-children {
                > a {
                    padding-bottom: 0;
                }
            }

            // Styles for active menu items
            &.current-menu-item,
            &.current-post-parent,
            &.current_page_item,
            &.current_page_parent {

                > a {
                    font-weight: bold;
                    color: $navbar-default-link-hover-color;
                    background: $navbar-default-link-hover-bg;
                }
            }

            // Shrink padding of submenu items
            ul {
                li {
                    > a {
                        padding: .5em .75em .5em 1.5em;
                    }
                }
            }
        }
    }
}
