.fl-module-post-grid {
    .fl-module-content {
        .fl-post-feed {
            .fl-post-feed-post {
                max-width: none;

                @media screen and (min-width: $screen-xs-min) and (max-width: $screen-xs-max) {
                    float: left;
                    max-width: 46%;
                    margin: 1em 2%;
                }
            }
            .fl-post-feed-header {
                margin-bottom: 0;

                @media screen and (min-width: $screen-sm-min) {
                    margin-left: 37%;
                }
            }
        }
    }
}
