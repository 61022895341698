/****************************************************
*************** Scroll To-Top Button ****************
****************************************************/
.to-top {
    position: fixed;
    bottom: 1em;
    right: 1em;
    z-index: 100;
    border: 1px solid #fff;
    padding: .33em .75em;
    background: $color-primary;
    color: #fff;
    opacity: 1;
    mix-blend-mode: luminosity;
    transition: opacity $offcanvas-duration ease-in-out;

    .move-left &, .move-right & {
        opacity: 0;
    }
    &:hover{
        background: lighten($color-primary,10%);
        color:#FFF;
    }
}




/****************************************************
********************* CTA Buttons *******************
****************************************************/
input.cta-btn,
a.cta-btn,
.cta-btn a,
.fl-cta-button a.fl-button,
a.uabb-button {
    padding: 8px 18px 8px 18px;
    border-radius: 3px;
    font-family: $font-family-serif;
    font-size: $font-size-base;
    font-weight: 600;
    background: $color-primary;
    color: #FFF;

    &:hover{
        color: #FFF !important;
        background: lighten($color-primary,10%);
    }
}




/****************************************************
******************* Floaty Buttons ******************
****************************************************/
.floaty-wrap {
    position: fixed;
    left: 0;
    top: 40%;
    z-index: 100;

    .floaty_buttons {
        margin: 0;
        padding: 0;
        list-style: none;

        .menu-item > a{
            display: block;
            padding: 1.2em;
            padding-left: 2.4em;
            background: $navbar-default-bg;
            color: $navbar-default-link-color;
            transition: 250ms all ease-in-out;
            transform: translateX(-1.2em);

            &:hover {
                background: $navbar-default-link-hover-bg;
                color: $navbar-default-link-hover-color;
                transform: translateX(0);
                -ms-transform: translateX(0);
            }
        }
    }
}
