.image-wrap,
.bg-img-cover {
    -ms-behavior: url(/app/themes/sage-theme/dist/images/backgroundsize.min.htc);
}

.centered-box {
    .internet-explorer9 & {
        -ms-transform: translateX(-50%) translateY(-50%) !important;
    }

    .internet-explorer8 & {
        position: absolute;
        top: 22% !important;
        right: 0 !important;
        bottom: auto !important;
        left: 0 !important;
        width: 100% !important;
    }
}

.ms-info {
    .internet-explorer8 & {
        @extend .container;
    }
}

.logo-link {
    .internet-explorer8 & {
        background-image: url(../images/logo_berndt_kaelte.png);
        -ms-behavior: url(/app/themes/sage-theme/dist/images/backgroundsize.min.htc);
    }
}


.oldie-warning {
    background: $color-primary;
    p {
        @extend .container;
        padding: 1em;
        color: #fff;
        font-size: $font-size-small;
        text-align: center;
    }
    .close-oldie {
        display: inline-block;
        margin-left: 1em;
        border: 1px solid #fff;
        padding: 0 .5em;
        color: inherit;
        cursor: pointer;
        font-size: 0.8em;
    }
}
