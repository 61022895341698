#hero {

  &.fl-row {
    .fl-row-content-wrap {
      padding: 4em 0;
      color: #fff;

      .uabb-infobox-title-wrap {
        font-family: $font-family-serif;
        font-size: 48px;
        font-weight: 600;
      }
      .uabb-infobox-text-wrap {
        font-size: $font-size-large;
      }
    }
  }
    .header-custom-image,
    .header-post-thumbnail,
    .header-default-image,
    .header-no-image {
        @extend .bg-img-cover;
        @extend .hero-area-content; // @ common/_helper-classes.scss
    }

    .header-no-image {
        background: $color-primary;
        > div {
            @extend .container;
        }
        p {
            width: 100%;
            padding: 1em;
            color: #fff;
            text-align: center;

            @media screen and (min-width: $screen-sm-min) {
                font-size: 1.5em;
            }
        }
    }
}
