#desktop-nav {
    @extend .hidden-xs;
    @extend .hidden-is;
    position: relative;
    z-index: 100;
    width: 100%;
    text-transform: uppercase;


    nav {
        @extend .container;
    }

    &.affix {
        position: fixed;
        top: 0;
        background: #fff;
    }

    // Generic Menu item Styles
    li {
      &.pipe-separator {
        color: $color-primary;
      }

        a {
            padding: $nav-link-padding;
            color: $desktop-nav-link-color;

            @media screen and (max-width: $screen-sm-max) {
              font-size: 13px;;
              padding: $nav-link-padding 1em;
            }

            &:hover {
                background: $desktop-nav-link-hover-bg;
                color: $desktop-nav-link-hover-color;
            }
        }

        // Second Nav Level
        .sub-menu {
            display: none;
            background: $desktop-nav-bg;

            @media screen and (min-width: $screen-sm-min) {
                display: block;
                visibility: hidden;
                position: absolute;
                top: 100%;
                left: 0;
                z-index: 10;
                min-width: 100%;
                transition: 0.1s .15s; /* delay of 0.15 seconds on hover off */
            }
        }
    }

    // Current Menu Item Styles
    .current-menu-item,
    .current-menu-ancestor {
        > a {
            background: $desktop-nav-link-active-bg;
            color: $desktop-nav-link-active-color;

            &:hover,
            &:active,
            &:focus {
                background: $desktop-nav-link-active-bg;
                color: $desktop-nav-link-active-color;
            }
        }
    }
}




// Open First Level Sub-Menus of the last item to the left
#desktop-nav {
    nav {
        @media screen and (min-width: $screen-sm-min) {
            > ul {
                > li:last-child {
                    .sub-menu {
                        left: auto;
                        right: 0;

                        // Open Second Level Sub-Menus of the last item to the left
                        .sub-menu {
                            right: 100%;
                        }
                    }
                }
            }

            li:hover {
                > .sub-menu {
                    visibility: visible;
                    transition-delay: 0s;
                }
            }
        }
    }
}


// Third Nav Level
#desktop-nav {
    .sub-menu {
        .sub-menu {
            @media screen and (min-width: $screen-sm-min) {
                top: 0;
                left: 100%;
            }
        }
    }
}
