body {

}

h1,
h2,
h3,
h4 {
  font-style: italic;
}

h1{
}

h2 {
  .home & {
    font-size: $font-size-h1;
  }

}

h3 {
  font-family: $font-family-sans-serif;
  font-weight: 600;
}

h4 {

}

.tagline{
    font-weight:300;
    font-size: $font-size-large;
}
