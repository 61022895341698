#cookie-notice {
    background: $color-secondary !important;

    .cookie-notice-container {
        #cn-notice-text {
            font-size: $font-size-small;
            line-height: 1;
        }

        a#cn-accept-cookie {
            @extend .cta-btn;
            padding: .25em 1.5em;
            display: block;
            margin: .5em auto;
            max-width: 200px;
            font-size: 12px;
            text-transform: none;

            @media screen and (min-width: $screen-sm-min) {
                display: inline-block;
                margin: 0 1em;
            }
        }
    }
}
