#hero {
    // .master-slider-parent, .master-slider, .ms-container, .ms-inner-controls-cont, .ms-slide-container, .ms-slide {
    //   height: 100%;
    // }
    .masterslider-wrap {
        @extend .hero-area-content; // @ common/_helper-classes.scss
        position: relative;

        .master-slider {
            z-index: 10;

            .ms-inner-controls-cont {
                position: static;
            }

            .ms-view,
            .ms-slide {
                @extend .hero-area-content; // @ common/_helper-classes.scss
            }

            img {
                max-width: none;
            }

            .ms-slide-info {
                @extend .centered-box;
                bottom: auto !important;
                margin: 0;
                padding: 0;
                min-height: initial;
                background: rgba(255, 255, 255, 0.75);
                pointer-events: none;

                a, button {
                    pointer-events: all !important;
                    position: relative;
                }

                .ms-info {
                    padding: 1em;

                    h1,h2,h3,h4 {
                        margin-top: 0;
                    }
                }

                @media screen and (max-width: $screen-xs-max) {
                    top: auto;
                    bottom: 0 !important;
                    left: auto;
                    transform: none;

                    .ms-info {
                        padding: 0.5em;
                        max-height: 200px;
                        font-size: 0.75em;
                        overflow: hidden;

                        h2 {
                            font-size: 1.5em;
                        }
                    }
                }
            }
        }

        .ms-bullets {
            z-index: 20;
            width: 50px;

            @media screen and (min-width: $screen-sm-min) {
                bottom: 20px  !important;
            }

            .ms-bullet {
                width: 12px;
                height: 12px;
                border-radius: 100%;
                background: #fff;

                &.ms-bullet-selected {
                    background: $color-primary;
                }
            }
        }
    }
}
