// Error 404 Page Styles
.error404 {
    main {
        text-align: center;
    }
    .error404-content {
        padding-top: 1em;

        .search-form {
            padding: 1em;
        }
    }
}
