/****************************************************
*************** Global Helper Classes ***************
****************************************************/

// Covered and centered background-image
.bg-img-cover {
    position: relative;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

// vertically and horizontally centered box (absolute - relative to parent)
.centered-box {
    @extend .container;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

// control the height of the hero content
.hero-area-content {
    min-height: 250px;

    @media screen and (min-width: $screen-sm-min){
        min-height: 300px;
    }

    @media screen and (min-width: $screen-md-min){
        min-height: 350px;
    }

    @media screen and (min-width: $screen-lg-min){
        min-height: 400px;
    }

    @media screen and (min-width: 2000px){
        min-height: 550px;
    }
}

// Set pipes after each element except the last one
.pipe-after {
    &::after {
        padding: 0 .5em;
        content: '|';
        vertical-align: top;
    }
    &:last-child {
        &::after {
            display: none;
        }
    }
}

// Set pipes before each element except the first one
.pipe-before {
    &::before {
        padding: 0 .5em;
        content: '|';
        vertical-align: top;
    }
    &:first-child {
        &::before {
            display: none;
        }
    }
}

// Separator list item created by piped walker
.pipe-separator {
    padding: $nav-link-padding 0;
}

// Horizontally justified list with no flexbox fallback
%justified-list {
    @include list-unstyled;
    margin: 0;
    padding: 0;
    width: 100%;
    font-size: 0;
    text-align: justify;
    text-justify: distribute-all-lines; /* distribute items in IE */
    vertical-align: middle;
    zoom: 1;

    &::after {
        display: inline-block;
        width: 100%;
        content: '';
    }

    li {
        text-align: center;

        a {
          display: block;
        }
    }

    > li {
        display: inline-block;
        position: relative;
    }

    .flexbox & {
        display: flex;
        flex-direction: row;
        align-items: center;
        align-content: center;
        flex-wrap: wrap;
        justify-content: space-between;

        &::after {
            display: none;
        }

        > li {
            flex: 1 1 auto;
            flex-direction: column;

            &.pipe-separator {
                flex-grow: 0;
            }
        }
    }
}

// Hide icon and show label in menu-items (revert backend setting)
.icon-to-text {
    i {
        display: none;
    }

    span.visuallyhidden {
        position: static;
        margin: auto;
        width: auto;
        height: auto;
        clip: auto;
        overflow: auto;
    }
}

// Make absolute positioned modules editable. You have to set the class
.fl-builder-edit .absolute-fl-module .fl-module-content {
    top: 0 !important;
    margin-top: 0 !important;
}

// Color classes
.beige {
  background: $color-tertiary;
}

.orange {
  background: $color-secondary;
}
